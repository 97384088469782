import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import * as moment from 'moment';
import Img from "gatsby-image";
import { withTranslation } from 'react-i18next';

const PostOverview = ({ post, t, i18n, language }) => {
    const { title, featuredImage, date, slug, uri, categories } = post;
    const defaultPostImage = useStaticQuery(graphql`
        query defaultPostImage {
            file(relativePath: { eq: "default-post-image.jpg" }) {
                childImageSharp {
                    thumbnail: fixed(width: 347, height: 215) {
                        ...GatsbyImageSharpFixed_withWebp_tracedSVG
                    }
                }
            }
        }
    `);

    const category = categories ? categories.nodes[0] : null;

    // Sets the post image to the image supplied else uses a default post image
    let postImage = defaultPostImage.file.childImageSharp.thumbnail;
    let postAltTag = t('post-overview.image-alt');

    if(featuredImage !== null) {
        if (typeof featuredImage.node !== "undefined") {
            postImage = featuredImage.node.imageFile.childImageSharp.thumbnail;
            postAltTag = featuredImage.node.altText;
        }
    }

    return (
        <Link className="c-latest" to={uri} aria-label={`${t('post-overview.link-label-a')} ${title} ${t('post-overview.link-label-b')}`}>
            <article className="c-latest__inner">
                {category &&
                    <Link className="c-latest__tag" to={`/category/${category.slug}/`} dangerouslySetInnerHTML={{ __html: category.name }}></Link>
                }
                <Img className="c-latest__image" fixed={postImage} alt={postAltTag} />
                {date &&
                    <span className="c-latest__date">{moment(date).format('DD MMM YYYY')}</span>
                }
                {title &&
                    <h1 className="c-latest__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                }
            </article>
        </Link>
    );
};

export default withTranslation()(PostOverview);
